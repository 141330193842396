import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import {
  PortfoliosByBuyerAndSellerIdsRequest,
  ManagePortfolioAccountsByLenderRequest,
  ManagePortfolioAccountsByRowRequest,
  UploadPortfolioRequest,
  ValidatePortfolioTemplateHeadersRequest,
  PortfolioSplitRequest,
  AcceptBidRequest,
  RespondPortfolioListRequest,
  GeneratePortfolioDSSRequest,
  SaveNumberOfDaysToProvideMediaFileRequest,
} from './types'

export const execPsaCommand = async (
  portfolioId: any,
  psaCommand: any,
  businessType: any,
  additionalInformation: any
) => {
  await httpClient.post(urls.PortfolioExecPsaCommand, {
    portfolioId,
    psaCommand,
    businessType,
    additionalInformation,
  })
}

export const managePortfolioAccountsByRow = async (
  request: ManagePortfolioAccountsByRowRequest
): Promise<void> => {
  await httpClient.post(urls.PortfolioManageAccountsByRow, request)
}

export const managePortfolioAccountsByLender = async (
  request: ManagePortfolioAccountsByLenderRequest
): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioManageAccountsByLender,
    request
  )
  return response?.data
}

export const savePortfolioTemplate = async (request: any): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioSavePortfolioTemplate,
    request
  )
  return response?.data
}

export const validatePortfolioTemplateHeaders = async (
  request: ValidatePortfolioTemplateHeadersRequest
): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioValidateTemplateHeaders,
    request
  )
  return response?.data
}

export const confirmFinalFundingAsync = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.post(urls.PortfolioConfirmFinalFunding, {
    portfolioId,
  })
  return response?.data
}

export const excludeOutOfStatAccounts = async (
  portfolioId: string
): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioExcludeOutOfStatAccounts,
    {
      portfolioId,
    }
  )
  return response?.data
}

export const uploadPortfolio = async (
  uploadPortfolioRequest: UploadPortfolioRequest
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioTemplateId', uploadPortfolioRequest.portfolioTemplateId)
  form.append('file', uploadPortfolioRequest.file)
  uploadPortfolioRequest?.assetTypes?.forEach((assetType, index) => {
    form.append(`assetTypes[${index}]`, assetType)
  })
  form.append(
    'cutOffDateUtc',
    uploadPortfolioRequest.cutOffDateUtc.toDateString()
  )
  const response = await httpClient.post(urls.PortfolioUploadPortfolio, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const getPortfolioBuyersBySellerId = async (
  sellerIds: string[] | undefined | null
): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioGetPortfolioBuyersBySellerId,
    {
      sellerIds,
    }
  )
  return response?.data
}

export const getPortfoliosByBuyerAndSellerIds = async (
  request: PortfoliosByBuyerAndSellerIdsRequest | null
): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioGetPortfoliosByBuyerAndSellerIds,
    request
  )
  return response?.data
}

export const splitPortfolio = async (
  request: PortfolioSplitRequest
): Promise<any> => {
  const response = await httpClient.post(urls.PortfolioSplit, request)
  return response?.data
}

export const acceptBid = async (request: AcceptBidRequest): Promise<any> => {
  const response = await httpClient.post(urls.PortfolioAcceptBid, request)
  return response?.data
}

export const acceptPortfolioDisclosure = async (
  portfolioNumber: number
): Promise<any> => {
  const response = await httpClient.post(
    urls.AcceptPortfolioDisclosure,
    portfolioNumber
  )
  return response?.data
}

export const rewindPsaFinalVersion = async (
  portfolioId: string | undefined | null,
  snapshotAddress: string | undefined | null
): Promise<any> => {
  const response = await httpClient.post(urls.PortfolioRewindPsaFinalVersion, {
    portfolioId,
    snapshotAddress,
  })
  return response?.data
}

export const respondPortfolioList = async (
  request: RespondPortfolioListRequest
): Promise<any> => {
  const response = await httpClient.post(urls.PortfolioRespondList, request)
  return response?.data
}

export const generatePortfolioDSS = async (
  request: GeneratePortfolioDSSRequest | undefined
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioId', request?.portfolioId || '')
  form.append('businessId', request?.businessId || '')
  form.append('surveyId', request?.surveyId.toString() || '')
  form.append('file', request?.file)
  const response = await httpClient.post(
    urls.PortfolioGeneratePortfolioDSS,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return response?.data
}

export const validatePortfolioFile = async (
  request: any | undefined
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioType', request?.portfolioType || '')
  form.append('sellerUploadTemplateId', request?.sellerUploadTemplateId || '')
  form.append('file', request?.file)
  const response = await httpClient.post(
    urls.PostSaleGetCanCreateUnspecifiedPostSale,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return response?.data
}

export const SendInvoiceCall = async (
  request: any | undefined
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioId', request.variables.portfolioId)
  form.append('invoiceType', request.variables.invoiceType)
  form.append('regenerate', request.variables.regenerate)
  const response = await httpClient.post(urls.PortfolioSendInvoice, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const SubmitScrubPortfolio = async (
  request: any | undefined
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioId', request.scrubRequest.portfolioId)
  form.append('scrubIndex', request.scrubRequest.scrubIndex)
  const response = await httpClient.post(urls.ScrubPortfolio, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const RequestScoring = async (
  request: any | undefined
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioNumber', request.scoringRequest.portfolioNumber)
  form.append('randomization', request.scoringRequest.randomization)
  form.append('numberOfAccounts', request.scoringRequest.numberOfAccounts)
  form.append('buyerId', request.scoringRequest.buyerId)
  const response = await httpClient.post(urls.ScorePortfolio, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const saveMediaUploadNumberOfDays = async (
  request: SaveNumberOfDaysToProvideMediaFileRequest
): Promise<any> => {
  const response = await httpClient.post(
    urls.SaveNumberOfDaysToProvideMediaFileUrl,
    request
  )
  return response?.data
}
