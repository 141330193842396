/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { useLazyQuery } from '@apollo/client'
import { GET_BULK_REQUEST_INFO_SELECTED_ACCOUNTS } from 'src/graphql/operations/queries/postSale'
import {
  BusinessInfo,
  InfoRequest,
  InfoRequestResponse,
} from 'src/graphql/models/Business'
import { BulkUploadAccountsData } from 'src/graphql/models/PostSale'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  ACCOUNT_DETAIL,
  CREATE_REQUEST_INPUTMETADATA,
  POSTSALE,
} from 'src/routes'
import { useFormik } from 'formik'
import { TdNoEllipsis } from 'src/styles/layout'
import { HeaderInfos } from 'src/components/Header/styles'
import { DropUpload } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import {
  DataValidation,
  FileParseResult,
  ParseXlsxFile,
} from 'src/utils/parseFile'
import { GetPostSaleRequestTemplateFileColumns } from 'src/utils/fileHeaders'
import { useProcessBulkUploadRequests } from 'src/graphql/operations/mutations/postSale'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { toLowerCaseProperties } from 'src/utils/text'
import ExportExcelButton from 'src/components/ExportExcel'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown/MultipleSelectionDropDown'
import {
  BulkUploadSection,
  Content,
  Header,
  ModalHeader,
  UploadItem,
} from './styles'
import { RequestType, RequestTypeEnum, RequestTypeList } from '../RequestType'
import {
  DataTable,
  DataTableState,
  Icon,
  textSecondary,
} from 'everchain-uilibrary'
import {
  getMyPortfoliosSellers,
  getPortfolioBuyersByBuyerId,
  getPortfolioType,
} from 'src/data/features/get/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { downloadBase64File } from 'src/utils/fileDownload'
import { AccountRequest } from 'src/data/features/get/account/types'
import { getBulkUploadAccounts } from 'src/data/features/get/account/account'
import { PortfoliosByBuyerAndSellerIdsRequest } from 'src/data/features/post/portfolio/types'
import {
  getPortfolioBuyersBySellerId,
  getPortfoliosByBuyerAndSellerIds,
} from 'src/data/features/post/portfolio/portfolio'
import { getBulkUploadTemplateFile } from 'src/data/features/get/postSale/postSale'
import { useMutation } from '@tanstack/react-query'
import { getStandardUri } from 'src/utils/common'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

// eslint-disable-next-line max-len
const BulkUploadMain: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [initialValues] = useState<any>({})
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [totalDataSize, setTotalDataSize] = useState<number>(5)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [file, setFile] = useState<any>(null)
  const [fileParseResult, SetFileParseResult] = useState<FileParseResult>()
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const [allValidAccounts, setAllValidAccounts] = useState<any>()
  const [sellerSelected, setSellerSelected] = useState<string[]>([])
  const [buyerSelected, setBuyerSelected] = useState<string[]>([])
  const currentSellerIds = useRef<string[] | null | undefined>([])
  const [isGettingBulkUploadTemplateFile, setIsGettingBulkUploadTemplateFile] =
    useState<boolean>(false)
  const [portfolioNumberSelected, setPortfolioNumberSelected] =
    useState<number>()
  const [accountsRequest, setAccountsRequest] = useState<AccountRequest | null>(
    null
  )
  const [isGettingAllAccounts, setIsGettingAllAccounts] =
    useState<boolean>(false)
  const [
    portfolioByBuyerAndSellerIdsRequest,
    setPorfolioByBuyerAndSellerIdsRequest,
  ] = useState<PortfoliosByBuyerAndSellerIdsRequest | null>(null)

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
  }, [])
  const accept =
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: disableUI,
    multiple: false,
  })

  const validate = (values: any) => {
    const errors: any = {}
    if (isBuyerType && !values.buyerSelected) errors.buyerSelected = 'Required'

    if (isSeller && !values.sellerSelected) errors.sellerSelected = 'Required'

    return errors
  }

  const { userPermissions } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyerType = userPermissions.type.toLowerCase() === 'buyer'

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const { data: sellerList, isFetching: loadingSellerList } = useCustomQuery<
    BusinessInfo[]
  >(['getMyPortfoliosSellers'], async () => getMyPortfoliosSellers(), {
    enabled: true,
    cacheTime: 0,
  })

  const {
    data: buyersList,
    isFetching: loadingBuyerList,
    refetchWithParams,
  } = useCustomQuery<BusinessInfo[]>(
    ['getPortfolioBuyersBySellerId'],
    async () => {
      if (isBuyerType) {
        return getPortfolioBuyersByBuyerId()
      } else {
        return getPortfolioBuyersBySellerId(currentSellerIds.current)
      }
    },
    { enabled: false }
  )

  const getBuyerList = (sellerIds: string[] | null = null) => {
    currentSellerIds.current = sellerIds
    refetchWithParams({
      sellerIds: sellerIds,
    })
  }

  const { data: portfolioList, isFetching: loadingPortfolioList } =
    useCustomQuery<number[]>(
      ['getPortfoliosByBuyerAndSellerIds', portfolioByBuyerAndSellerIdsRequest],
      async () =>
        getPortfoliosByBuyerAndSellerIds(portfolioByBuyerAndSellerIdsRequest),
      {
        enabled: !!portfolioByBuyerAndSellerIdsRequest,
      }
    )

  const { data: portfolioTypeId, isFetching: loadingPortfolioType } =
    useCustomQuery<number>(
      ['getPortfolioType', portfolioNumberSelected],
      async () => getPortfolioType(portfolioNumberSelected),
      {
        enabled:
          portfolioNumberSelected != undefined && portfolioNumberSelected > 0,
        cacheTime: 0,
      }
    )

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleFilterClick()
      setSubmitting(false)
    },
  })

  const { data: accountsResponse, isFetching: loadingAccounts } =
    useCustomQuery<BulkUploadAccountsData>(
      ['getBulkUploadAccounts', accountsRequest],
      async () =>
        getBulkUploadAccounts(accountsRequest).finally(() => {
          const retrieveBulkUploadData = localStorage.getItem('bulkUploadData')
          if (retrieveBulkUploadData !== null) {
            const bulkUploadData: any = JSON.parse(retrieveBulkUploadData || '')
            setAccountsSelected(bulkUploadData.accountsSelected)
          }
        }),
      {
        enabled: !!accountsRequest,
      }
    )

  const accountsData = accountsResponse?.accounts || []

  const { data: allAccountsData, isFetching: loadingAllAccountsData } =
    useCustomQuery<BulkUploadAccountsData>(
      ['getBulkUploadAllAccounts', isGettingAllAccounts],
      async () => {
        setIsGettingAllAccounts(false)
        return getBulkUploadAccounts({
          portfolioNumber: formCollection.values.pidSelected,
          pagination: null,
          sellerIds: formCollection.values.sellerSelected
            ? formCollection.values.sellerSelected.map((b: any) => b.id)
            : undefined,
          accountIds: undefined,
          buyerId: undefined,
          buyerIds: undefined,
          lastSSN: undefined,
          sellerId: undefined,
        })
      },
      {
        enabled: isGettingAllAccounts,
      }
    )

  const { processBulkUploadRequests } = useProcessBulkUploadRequests({
    onCompleted: (data) => {
      history.push({
        pathname: getStandardUri(
          `${POSTSALE}/request/file-upload/processing-errors`
        ),
        state: {
          data,
          allValidAccounts,
          requestTypeId: formCollection.values.requestTypeSelected.Id,
          isUkCountry,
        },
      })
      enqueueSnackbar('Accounts have been processed', notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('Error processing accounts', notifyError)
    },
  })

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      history.push({
        pathname: getStandardUri(
          `${POSTSALE}/request/file-upload/processing-errors`
        ),
        state: {
          data: data.data,
          allValidAccounts,
          requestTypeId: formCollection.values.requestTypeSelected.Id,
          isUkCountry,
        },
      })
      enqueueSnackbar('Accounts have been processed', notifySuccess)
    },
    onError: (data: any) => {
      history.push({
        pathname: getStandardUri(
          `${POSTSALE}/request/file-upload/processing-errors`
        ),
        state: {
          data: data.data,
          allValidAccounts,
          requestTypeId: formCollection.values.requestTypeSelected.Id,
          isUkCountry,
        },
      })
    },
  })

  useEffect(() => {
    const retrieveBulkUploadData = localStorage.getItem('bulkUploadData')
    if (retrieveBulkUploadData !== null) {
      const bulkUploadData: any = JSON.parse(retrieveBulkUploadData || '')
      formCollection.setFieldValue(
        'requestTypeSelected',
        bulkUploadData.requestTypeSelected
      )

      formCollection.setFieldValue(
        'sellerSelected',
        bulkUploadData.sellerSelected
      )
      const sellerSelectedIds = bulkUploadData.sellerSelected
        ? bulkUploadData.sellerSelected.map((b: any) => b.id)
        : null
      const buyerSelectedIds = bulkUploadData.buyerSelected
        ? bulkUploadData.buyerSelected.map((b: any) => b.id)
        : null
      if (isSeller) {
        getBuyerList(sellerSelectedIds)
      } else {
        getBuyerList()
      }

      setPorfolioByBuyerAndSellerIdsRequest({
        sellerIds: sellerSelectedIds,
        buyerIds: buyerSelectedIds,
      })

      setAccountsRequest({
        sellerIds: formCollection.values.sellerSelected
          ? formCollection.values.sellerSelected.map((b: any) => b.id)
          : undefined,
        buyerIds: formCollection.values.buyerSelected
          ? formCollection.values.buyerSelected.map((b: any) => b.id)
          : undefined,
        portfolioNumber: bulkUploadData.pidSelected,
        pagination: gridState,
        accountIds: undefined,
        buyerId: undefined,
        lastSSN: undefined,
        sellerId: undefined,
      })
    } else if (isSeller) {
      if (formCollection.values.sellerSelected) {
        getBuyerList(formCollection.values.sellerSelected.map((b: any) => b.id))
      }
    } else {
      getBuyerList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTotalDataSize(accountsResponse?.totalOfDataResult ?? 25)
  }, [accountsResponse])

  useEffect(() => {
    setAccountsSelected(
      allAccountsData?.accounts?.map((x) => x.portfolioRowId) ?? []
    )
  }, [allAccountsData])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      const accountsId = accountsData.map((x) => x.portfolioRowId)
      if (accountsId && accountsId?.length > 0) {
        const allIds = accountsId.concat(accountsSelected)
        const dataSelected = [...new Set([...allIds, ...accountsSelected])]

        if (dataSelected !== undefined && dataSelected.length > 0)
          setAccountsSelected(dataSelected)
      }
    }

    if (e === 'Unselect all rows (current page)') {
      const accountsId = accountsData.map((x) => x.portfolioRowId)

      const ids = accountsSelected.filter((x) => !accountsId?.includes(x))

      if (ids !== undefined) setAccountsSelected(ids)
    }

    if (e === 'Select all rows (all pages)') {
      if (allAccountsData && allAccountsData?.accounts?.length > 0) {
        setAccountsSelected(
          allAccountsData?.accounts.map((x) => x.portfolioRowId) ?? []
        )
      } else {
        setIsGettingAllAccounts(true)
      }
    }

    if (e === 'Unselect all rows (all pages)') setAccountsSelected([])
  }

  const [getInfoRequest, { data: selectedAccountsInfo }] =
    useLazyQuery<InfoRequestResponse>(GET_BULK_REQUEST_INFO_SELECTED_ACCOUNTS, {
      fetchPolicy: 'network-only',
      variables: {
        accountsIds: accountsSelected,
      },
      onCompleted: () => onReadyToCreate(),
    })

  const [openInfoRequestWarningsModal, setOpenInfoRequestWarningsModal] =
    useState<boolean>(false)

  const onHandleCreate = () => {
    getInfoRequest()
  }

  const onReadyToCreate = () => {
    if (
      (formCollection.values.requestTypeSelected.Id != RequestTypeEnum.Info &&
        formCollection.values.requestTypeSelected.Id !=
          RequestTypeEnum.InaccurateData) ||
      selectedAccountsInfo?.bulkUploadAccounts.length == 0
    ) {
      const bulkUploadSelection = {
        sellerSelected: formCollection.values.sellerSelected,
        buyerSelected: formCollection.values.buyerSelected,
        requestTypeSelected: formCollection.values.requestTypeSelected,
        pidSelected: formCollection.values.pidSelected,
        accountIds: formCollection.values.accountIds,
        accountsSelected,
      }

      window.localStorage.setItem(
        'bulkUploadData',
        JSON.stringify(bulkUploadSelection)
      )
      setDisableUI(true)
      history.push(getStandardUri(CREATE_REQUEST_INPUTMETADATA))
    } else {
      setOpenInfoRequestWarningsModal(true)
    }
  }

  const createAllInfoRequests = () => {
    const bulkUploadSelection = {
      sellerSelected: formCollection.values.sellerSelected,
      buyerSelected: formCollection.values.buyerSelected,
      requestTypeSelected: formCollection.values.requestTypeSelected,
      pidSelected: formCollection.values.pidSelected,
      accountIds: formCollection.values.accountIds,
      accountsSelected,
    }
    window.localStorage.setItem(
      'bulkUploadData',
      JSON.stringify(bulkUploadSelection)
    )
    setDisableUI(true)
    history.push(getStandardUri(CREATE_REQUEST_INPUTMETADATA))
  }

  const CreateNewInfoRequestOnly = () => {
    const bulkUploadSelection = {
      sellerSelected: formCollection.values.sellerSelected,
      buyerSelected: formCollection.values.buyerSelected,
      requestTypeSelected: formCollection.values.requestTypeSelected,
      pidSelected: formCollection.values.pidSelected,
      accountIds: formCollection.values.accountIds,
      accountsSelected: accountsSelected.filter(
        (accountId) =>
          !selectedAccountsInfo?.bulkUploadAccounts.some(
            (x) => x.accountId == accountId
          )
      ),
    }
    window.localStorage.setItem(
      'bulkUploadData',
      JSON.stringify(bulkUploadSelection)
    )
    setDisableUI(true)
    history.push(getStandardUri(CREATE_REQUEST_INPUTMETADATA))
  }

  const WarningsGridColumns: any[] = [
    {
      field: 'requestId',
      title: 'Request Id',
      width: 120,
      show: true,
      editable: true,
    },
    {
      field: 'loanId',
      title: 'Loan ID',
      width: 200,
      show: true,
      editable: true,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.loanId}
              </Button>
            ) : (
              <>{props.dataItem.loanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'portfolioId',
      title: 'Portfolio Id',
      show: true,
      editable: true,
      width: 150,
    },
    {
      field: 'infoType',
      title: 'Info Type',
      width: 200,
      show: true,
      editable: true,
    },
    {
      field: 'requestStatus',
      title: 'Status',
      width: 200,
      show: true,
      editable: true,
    },
    {
      field: 'infoRequested',
      title: 'Info Requested',
      width: 200,
      show: true,
      editable: true,
    },
  ]

  interface ExportDataTemplate {
    ['Request Id']: number
    ['Loan Id']: string
    ['PID']: number
    ['Info Type']: string | undefined
    ['Status']: string
    ['Info Requested']: string
  }

  const formatDataToExport = (
    entryData?: InfoRequest[]
  ): ExportDataTemplate[] => {
    const dataToExport: ExportDataTemplate[] = []

    entryData?.forEach((item: InfoRequest) => {
      const row: ExportDataTemplate = {
        'Request Id': item.requestId,
        'Loan Id': item.loanId,
        PID: item.portfolioNumber,
        'Info Type': item.infoType,
        Status: item.requestStatus,
        'Info Requested': item.infoRequested,
      }

      dataToExport.push(row)
    })

    return dataToExport
  }

  const InfoRequestWarningsDialog = (
    <Dialog
      open={openInfoRequestWarningsModal}
      onClose={() => setOpenInfoRequestWarningsModal(false)}
      maxWidth="lg"
      fullScreen
    >
      <ModalHeader
        py={3}
        px={5}
        m={0}
        bgcolor="green"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="white"
      >
        <Typography variant="h5" style={{ color: 'white' }}>
          Warnings
        </Typography>
        <IconButton
          size="small"
          onClick={() => setOpenInfoRequestWarningsModal(false)}
        >
          <Icon name="Close" color="white" />
        </IconButton>
      </ModalHeader>
      <DialogContent>
        <Grid item xs={12} md={12}>
          <DataTable
            className="requestCustomTable"
            data={selectedAccountsInfo?.bulkUploadAccounts}
            gridColumns={WarningsGridColumns}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          onClick={() => setOpenInfoRequestWarningsModal(false)}
        >
          Cancel
        </Button>
        <ExportExcelButton
          data={
            formatDataToExport(selectedAccountsInfo?.bulkUploadAccounts) || []
          }
          fileName="warnings"
        />
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={() => createAllInfoRequests()}
        >
          Create All Info Requests
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={() => CreateNewInfoRequestOnly()}
        >
          Create New Info Requests Only
        </Button>
      </DialogActions>
    </Dialog>
  )

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['portfolioRowId']) !==
      undefined
    )
      return true

    return false
  }

  useEffect(() => {
    const retrieveBulkUploadData = localStorage.getItem('bulkUploadData')

    if (retrieveBulkUploadData !== null) {
      const bulkUploadData: any = JSON.parse(retrieveBulkUploadData || '')
      if (!(loadingBuyerList && loadingPortfolioList)) {
        if (bulkUploadData.pidSelected) {
          formCollection.setFieldValue(
            'pidSelected',
            bulkUploadData.pidSelected
          )
          handlePidSelected(bulkUploadData.pidSelected)
        }

        if (bulkUploadData.buyerSelected) {
          formCollection.setFieldValue(
            'buyerSelected',
            bulkUploadData.buyerSelected
          )
          handleBuyerSelected(bulkUploadData.buyerSelected)
        }
      }
      if (bulkUploadData.sellerSelected) {
        formCollection.setFieldValue(
          'sellerSelected',
          bulkUploadData.sellerSelected
        )
      }

      window.localStorage.removeItem('bulkUploadData')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBuyerList, loadingPortfolioList])

  const GridColumns: any[] = [
    {
      title: 'Select',
      width: 80,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              value={props.dataItem['portfolioRowId']}
              onChange={(event) => {
                if (event.target.checked) {
                  setAccountsSelected([
                    ...accountsSelected,
                    props.dataItem['portfolioRowId'],
                  ])
                } else {
                  setAccountsSelected(
                    accountsSelected.filter(
                      (id) => id !== props.dataItem['portfolioRowId']
                    )
                  )
                }
              }}
              checked={handleAddCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'rowIndex',
      title: 'Row Index',
      width: 150,
      show: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      filter: 'text',
      show: true,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      filter: 'text',
      show: true,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      filter: 'text',
      show: true,
    },
    {
      field: 'firstName',
      title: 'First Name',
      filter: 'text',
      show: true,
    },
    {
      field: 'hasComplianceHold',
      title: 'Has Compliance Hold',
      filter: 'boolean',
      show: true,
      width: 190,
      render: (props: any) => {
        return <td>{props.dataItem['hasComplianceHold'] ? 'Yes' : 'No'}</td>
      },
    },
    {
      field: 'portfolioRowId',
      title: 'RowId',
      show: false,
    },
    {
      field: 'portfolioNumber',
      title: 'Portfolio Number',
      show: false,
    },
    {
      field: 'portfolioRowGuid',
      title: 'ECAID',
      filter: 'text',
      show: true,
      width: 300,
    },
  ]

  const handleSellerSelected = (business: BusinessInfo[]) => {
    setAccountsSelected([])

    const businessIds = business.map((b) => b.id)
    if (isSeller) {
      formCollection.setFieldValue('buyerSelected', undefined)
      setBuyerSelected([])
      formCollection.setFieldValue('pidSelected', undefined)
      formCollection.setFieldValue('accountIds', undefined)
      formCollection.setFieldValue('lastSSN', undefined)
      getBuyerList(businessIds)
    }
    formCollection.setFieldValue('sellerSelected', business)

    setPorfolioByBuyerAndSellerIdsRequest({
      sellerIds: businessIds,
      buyerIds: undefined,
    })

    const buyerSelectedIds = formCollection.values.buyerSelected
      ? formCollection.values.buyerSelected.map((b: any) => b.id)
      : undefined

    setAccountsRequest({
      sellerIds: businessIds,
      buyerIds: isSeller ? [] : buyerSelectedIds,
      pagination: gridState,
      accountIds: undefined,
      buyerId: undefined,
      lastSSN: undefined,
      portfolioNumber: undefined,
      sellerId: undefined,
    })
  }

  const handleBuyerSelected = (business: BusinessInfo[]) => {
    setAccountsSelected([])

    const businessIds = business.map((b) => b.id)
    if (isBuyerType) {
      formCollection.setFieldValue('sellerSelected', undefined)
      setSellerSelected([])
      formCollection.setFieldValue('pidSelected', undefined)
      formCollection.setFieldValue('accountIds', undefined)
      formCollection.setFieldValue('lastSSN', undefined)
    }
    formCollection.setFieldValue('buyerSelected', business)
    const sellerSelectedIds = formCollection.values.sellerSelected
      ? formCollection.values.sellerSelected.map((b: any) => b.id)
      : undefined
    setPorfolioByBuyerAndSellerIdsRequest({
      sellerIds: sellerSelectedIds,
      buyerIds: businessIds,
    })

    setAccountsRequest({
      sellerIds: sellerSelectedIds,
      buyerIds: businessIds,
      pagination: gridState,
      accountIds: undefined,
      buyerId: undefined,
      lastSSN: undefined,
      portfolioNumber: undefined,
      sellerId: undefined,
    })
  }

  const handlePidSelected = (portfolioNumber: number) => {
    setAccountsSelected([])
    formCollection.setFieldValue('pidSelected', portfolioNumber)
    setPortfolioNumberSelected(portfolioNumber)

    setAccountsRequest({
      sellerIds: formCollection.values.sellerSelected
        ? formCollection.values.sellerSelected.map((b: any) => b.id)
        : undefined,
      buyerIds: formCollection.values.buyerSelected
        ? formCollection.values.buyerSelected.map((b: any) => b.id)
        : undefined,
      portfolioNumber,
      pagination: gridState,
      accountIds: undefined,
      buyerId: undefined,
      lastSSN: undefined,
      sellerId: undefined,
    })
  }

  const handleFilterClick = () => {
    formCollection.validateForm()
    setAccountsSelected([])
    window.localStorage.removeItem('bulkUploadData')
    setAccountsRequest({
      sellerIds: formCollection.values.sellerSelected
        ? formCollection.values.sellerSelected.map((b: any) => b.id)
        : undefined,
      buyerIds: formCollection.values.buyerSelected
        ? formCollection.values.buyerSelected.map((b: any) => b.id)
        : undefined,
      portfolioNumber: formCollection.values.pidSelected,
      accountIds:
        formCollection.values.accountIds &&
        formCollection.values.accountIds.length > 0
          ? formCollection.values.accountIds.replace(/\s/g, '').split(',')
          : undefined,
      lastSSN: formCollection.values.lastSSN,
      pagination: gridState,
      buyerId: undefined,
      sellerId: undefined,
    })
  }

  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const isUkCountry = country === 'UK'

  const renderSellerSelect = () => {
    const sellerSelect = sellerList ? sellerList.map((b) => b.name) : []

    const handleChangeSeller = (value: any) => {
      setSellerSelected(value)
      const resultados = sellerList
        ? sellerList.filter((obj) => value.includes(obj.name))
        : []

      handleSellerSelected(resultados)
    }

    return (
      <MultipleSelectionDropDown
        data-test-id="dropdown-bulk-upload-select-seller"
        label="Seller"
        data={sellerSelect}
        disable={disableUI}
        handleSelectionChange={handleChangeSeller}
        selectionState={sellerSelected}
      />
    )
  }
  const renderBuyerSelect = () => {
    const buyerSelect = buyersList ? buyersList.map((b) => b.name) : []

    const handleChangeBuyer = (value: any) => {
      setBuyerSelected(value)
      const resultados = buyersList
        ? buyersList.filter((obj) => value.includes(obj.name))
        : []

      handleBuyerSelected(resultados)
    }

    return (
      <MultipleSelectionDropDown
        data-test-id="dropdown-bulk-upload-select-buyer"
        label="Buyer"
        data={buyerSelect}
        disable={disableUI}
        handleSelectionChange={handleChangeBuyer}
        selectionState={buyerSelected}
      />
    )
  }

  const { isFetching: loadingTemplateFile } = useCustomQuery<any>(
    ['getPortfolioFilesLink'],
    async () =>
      getBulkUploadTemplateFile(
        formCollection.values.requestTypeSelected.Id,
        country
      ).then((data) => {
        setIsGettingBulkUploadTemplateFile(false)
        downloadBase64File(data)
      }),
    { enabled: isGettingBulkUploadTemplateFile, cacheTime: 0 }
  )

  const onHandleDownloadTemplateFile = () => {
    setIsGettingBulkUploadTemplateFile(true)
  }

  const onHandleOpenUploadDialog = () => setShowUploadDialog(true)

  const OnHandleUploadFile = async () => {
    const columnsMetadata = GetPostSaleRequestTemplateFileColumns(
      formCollection.values.requestTypeSelected.Id,
      country
    )
    const result = await ParseXlsxFile(file, columnsMetadata)

    if (!result.passedValidation) {
      SetFileParseResult(result)
      setDisableUI(false)
      return
    }

    const { errors, dataConverted } = DataValidation(
      result.data,
      columnsMetadata,
      country
    )

    const validAccounts = dataConverted
      .filter((x: any) => !errors.some((i) => i.rowIndex === x.rowIndex))
      .map((item) => {
        return {
          ...item,
          accountId: item.ECAID,
          portfolioNumber: item.PortfolioNumber,
          lenderLoanId: item.LenderLoanId,
          metaData: JSON.stringify(item),
        }
      })

    if (result.data.length === validAccounts.length) {
      const allValidData = toLowerCaseProperties(validAccounts)
      if (formCollection.values.requestTypeSelected.Id === 12) {
        const submitData = validAccounts.map((row) => {
          const newRow = row

          newRow['dismissedDischargedType'] = row['Type (Dismissal/Discharge)']
          delete newRow['Type (Dismissal/Discharge)']
          newRow.metaData = JSON.stringify(newRow)

          return newRow
        })
        setAllValidAccounts(submitData)
        setAllValidAccounts(allValidData)
        saveRequest.mutate({
          requestType: formCollection.values.requestTypeSelected.Id,
          data: submitData,
        })
      } else {
        setAllValidAccounts(allValidData)
        saveRequest.mutate({
          requestType: formCollection.values.requestTypeSelected.Id,
          data: allValidData,
        })
      }
    } else {
      history.push({
        pathname: getStandardUri(`${POSTSALE}/request/file-upload/errors`),
        state: {
          allData: result.data,
          allValidData: JSON.stringify(validAccounts),
          errors,
          isUkCountry,
          requestTypeId: formCollection.values.requestTypeSelected.Id,
        },
      })
    }
  }

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <BulkUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Create Post-Sale Request(s)
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Grid container direction="column" style={{ padding: '15px' }}>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              {isSeller ? renderSellerSelect() : renderBuyerSelect()}
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              {isSeller ? renderBuyerSelect() : renderSellerSelect()}
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <TextField
                label="PID"
                select
                id="select-pid"
                value={formCollection.values.pidSelected ?? ''}
                fullWidth
                style={{ minWidth: '100px' }}
                disabled={
                  formCollection.values.buyerSelected === undefined &&
                  formCollection.values.sellerSelected === undefined
                }
              >
                <MenuItem
                  value=""
                  onClick={() => {
                    formCollection.setFieldValue('accountIds', undefined)
                    formCollection.setFieldValue('lastSSN', undefined)
                    setAccountsSelected([])
                  }}
                >
                  <em>Select PID</em>
                </MenuItem>
                {portfolioList?.map((portfolioNumber: number) => {
                  return (
                    <MenuItem
                      key={portfolioNumber}
                      value={portfolioNumber}
                      onClick={() => {
                        handlePidSelected(portfolioNumber)
                      }}
                    >
                      {portfolioNumber}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            {!isUkCountry && (
              <>
                <Grid item>
                  <TextField
                    label="Last 4 SSN"
                    id="select-SSN"
                    value={formCollection.values.lastSSN ?? undefined}
                    fullWidth
                    style={{ minWidth: '100px' }}
                    disabled={
                      formCollection.values.pidSelected === undefined &&
                      formCollection.values.buyerSelected === undefined &&
                      formCollection.values.sellerSelected === undefined
                    }
                    onChange={(e: any) => {
                      const numbers = /^(\d+)$/
                      if (
                        e.target.value.match(numbers) ||
                        e.target.value === ''
                      ) {
                        formCollection.setFieldValue('lastSSN', e.target.value)
                      }
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                  />
                </Grid>

                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
              </>
            )}
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                label="Account IDs"
                id="select-LoanIds"
                name="accountIds"
                value={formCollection.values.accountIds ?? undefined}
                fullWidth
                style={{ minWidth: '250px' }}
                disabled={
                  formCollection.values.pidSelected === undefined &&
                  formCollection.values.buyerSelected === undefined &&
                  formCollection.values.sellerSelected === undefined
                }
                onChange={formCollection.handleChange}
              />
            </Grid>

            <Grid item>
              <Button
                data-cy="filter-button"
                color="primary"
                variant="contained"
                type="submit"
              >
                Filter
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={3}
            style={{ paddingTop: '20px', paddingLeft: 0 }}
          >
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>
                Total of accounts selected: {accountsSelected.length}
              </Typography>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                data-cy="bulk-selection-options-button"
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                onClick={handleClick}
                disabled={
                  disableUI ||
                  accountsData.length == 0 ||
                  !formCollection.values.requestTypeSelected
                }
              >
                Bulk Selection Options{' '}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                  Select all rows (current page)
                </MenuItem>
                <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                  Unselect all rows (current page)
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item>
              <TextField
                label="Request Type"
                select
                id="select-request-type"
                value={formCollection.values.requestTypeSelected?.Name ?? ''}
                fullWidth
                style={{ minWidth: '200px' }}
                disabled={
                  !(
                    formCollection.values.pidSelected ||
                    formCollection.values.sellerSelected ||
                    formCollection.values.buyerSelected
                  )
                }
              >
                <MenuItem
                  value=""
                  onClick={() => {
                    formCollection.setFieldValue(
                      'requestTypeSelected',
                      undefined
                    )
                  }}
                >
                  <em>Select a request type</em>
                </MenuItem>
                {RequestTypeList(country).map((type: RequestType) => {
                  if (portfolioTypeId === 2) {
                    if (
                      type.Id !== RequestTypeEnum.Other &&
                      type.Id !== RequestTypeEnum.DismissedDischarged &&
                      type.Id !== RequestTypeEnum.Legal &&
                      type.Id !== RequestTypeEnum.Info &&
                      type.Id !== RequestTypeEnum.InaccurateData
                    )
                      return undefined
                  }
                  if (isUkCountry && type.Id === RequestTypeEnum.ScraMilitary) {
                    return null
                  }

                  return (
                    <MenuItem
                      key={type.Name}
                      value={type.Name}
                      onClick={() => {
                        formCollection.setFieldValue(
                          'requestTypeSelected',
                          type
                        )
                      }}
                    >
                      {type.Name}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Can
                do={PermissionCodeAccess.MarketPlace_PostSale_CreateRequest}
                on="any"
              >
                <Button
                  data-cy="create-post-sale-request-button"
                  variant="contained"
                  color="primary"
                  disabled={
                    disableUI ||
                    !(
                      formCollection.values.pidSelected ||
                      formCollection.values.sellerSelected ||
                      formCollection.values.buyerSelected
                    ) ||
                    !formCollection.values.requestTypeSelected ||
                    accountsSelected.length === 0
                  }
                  onClick={onHandleCreate}
                >
                  Create
                </Button>
              </Can>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onHandleDownloadTemplateFile}
                disabled={
                  disableUI || !formCollection.values.requestTypeSelected
                }
              >
                Download Template File
              </Button>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onHandleOpenUploadDialog}
                disabled={
                  disableUI ||
                  !formCollection.values.requestTypeSelected ||
                  userPermissions.type === 'internal'
                }
              >
                Upload Bulk Request File
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: '5px' }}>
            {(loadingSellerList ||
              loadingBuyerList ||
              loadingPortfolioList ||
              loadingAccounts ||
              loadingPortfolioType ||
              loadingAllAccountsData ||
              loadingTemplateFile) &&
              loadingPanel}
            <DataTable
              className="requestCustomTable"
              style={{
                width: '100%',
              }}
              data={
                formCollection.values.pidSelected ||
                formCollection.values.sellerSelected ||
                formCollection.values.buyerSelected
                  ? accountsData
                  : []
              }
              sortable
              useFilterMenu
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
              total={totalDataSize}
              onDataStateChange={(e: any) => {
                setGridState(e.dataState)
                setAccountsRequest({
                  portfolioNumber: formCollection.values.pidSelected,
                  sellerIds: formCollection.values.sellerSelected
                    ? formCollection.values.sellerSelected.map((b: any) => b.id)
                    : undefined,
                  buyerIds: formCollection.values.buyerSelected
                    ? formCollection.values.buyerSelected.map((b: any) => b.id)
                    : undefined,
                  accountIds:
                    formCollection.values.accountIds &&
                    formCollection.values.accountIds.length > 0
                      ? formCollection.values.accountIds
                          .replace(/\s/g, '')
                          .split(',')
                      : undefined,
                  pagination: e.dataState,
                  buyerId: undefined,
                  lastSSN: undefined,
                  sellerId: undefined,
                })
              }}
              gridColumns={GridColumns}
            />
          </Grid>
        </Grid>
        {InfoRequestWarningsDialog}
        <Dialog
          open={showUploadDialog}
          onClose={() => setShowUploadDialog(false)}
          aria-labelledby="form-filter"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Upload File</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowUploadDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <Box>
                {!file ? (
                  <DropUpload
                    isDragActive={isDragActive}
                    {...getRootProps()}
                    disabled={disableUI}
                  >
                    <input {...getInputProps()} />
                    <div className="upload-placeholder">
                      <Icon name="CloudUpload" className="upload-icon" />{' '}
                      <strong style={{ cursor: 'pointer' }}>Browse </strong> or
                      Drop file here to upload
                    </div>
                  </DropUpload>
                ) : (
                  <UploadItem>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      onClick={() => {
                        setFile(null)
                        SetFileParseResult(undefined)
                      }}
                      disabled={disableUI}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </UploadItem>
                )}
              </Box>
              {!fileParseResult?.passedValidation && (
                <Typography color="error">
                  {fileParseResult?.errorMessage}
                </Typography>
              )}
              <Box display="flex" justifyContent="end" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    disableUI && <CircularProgress size={15} color="primary" />
                  }
                  onClick={async () => {
                    setDisableUI(true)
                    OnHandleUploadFile()
                  }}
                  disabled={disableUI || !file}
                >
                  Upload File
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </BulkUploadSection>
    </form>
  )
}

export default BulkUploadMain
